import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth-guard.service';
import { environment } from 'src/environments/environment';
import { DraftsComponent } from './drafts/drafts.component';
import { WorkflowsComponent } from './workflows/workflows.component';
import { HistoryComponent } from './history/history.component';
import { OnlinerFormComponent } from './onlinerform/onlinerform.component';
import { ProcessedFormsComponent } from './processed-forms/processed-forms.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { BlankSplashComponentComponent } from './core/blank-splash-component/blank-splash-component.component';
import { roles } from './core/models/roles.model';
import { VexRoutes } from 'src/@vex/interfaces/vex-route.interface';
import { CustomLayoutComponent } from './core/custom-layout/custom-layout.component';

export const childrenRoutes: VexRoutes = [
  // Forms
  {
    path: 'newforms',
    pathMatch: 'full',
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [roles.init, roles.payroll] },
    component: OnlinerFormComponent,
  },
  //Drafts
  {
    path: 'drafts',
    pathMatch: 'full',
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [roles.admin, roles.init] },
    component: DraftsComponent,
  },
  //Workflows
  {
    path: 'workflows',
    pathMatch: 'full',
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [roles.admin, roles.init, roles.payroll, roles.reviewer, roles.user] },
    component: WorkflowsComponent,
  },
  //History
  {
    path: 'history',
    pathMatch: 'full',
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [roles.admin, roles.init, roles.payroll, roles.reviewer, roles.user] },
    component: HistoryComponent,
  },
  //Processed Forms
  {
    path: 'processedForms',
    pathMatch: 'full',
    canActivate: [AuthGuard, MsalGuard],
    data: { roles: [roles.admin, roles.init, roles.payroll, roles.reviewer] },
    component: ProcessedFormsComponent,
  }
];

const routes: Routes = [
  {
    path: 'id_token',
    redirectTo: 'blank',
    pathMatch: 'full',
    data: { isIgnoreRoute: true },
  },
  {
    path: 'blank',
    component: BlankSplashComponentComponent,
    pathMatch: 'full',
  },
  {
    path: 'login-failed',
    component: BlankSplashComponentComponent,
    pathMatch: 'full',
  }, // placeholder route for login failed
  {
    path: '',
    redirectTo: "workflows",
    pathMatch: "full",
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: childrenRoutes,
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }


