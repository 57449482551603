import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { forkJoin, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthService } from '../core/services/auth.service';
import { CommonService } from '../core/services/common.service';
import { CompensationService, defaultFilterStatusType } from '../core/services/compensation.service';
import { CompensationFilterService, FilterFormGroup } from '../core/services/compensation-filter.service';
import { OnlinerService, defaultOnliner } from '../core/services/onliner.service';
import { SnackBarService } from '../core/services/snackbar.service';

import { CompensationReviewStatusType } from '../core/models/compensationReview.model';
import { FilterStatusType, StatusType } from '../core/models/draftSummary.model';
import { StatusTypeEnum } from '../core/models/enums.model';
import { Employee } from '../core/models/employee.model';
import { WorkflowPageViewSummary } from '../core/models/workflowPageViewSummary.model';

@Component({
  selector: 'app-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.scss'],
})
export class WorkflowsComponent implements OnInit {
  isAdmin: boolean;
  isInitiator: boolean;
  isReviewer: boolean;
  isPayroll: boolean;
  isUser: boolean;
  loggedInUser: Employee;

  userCanSeeFilter: boolean = false;
  userCanSeeStatusFilter: boolean = false;
  workflows: WorkflowPageViewSummary[];
  filteredWorkflows: WorkflowPageViewSummary[];
  filterCriteriaForm: FilterFormGroup;
  closedStatusTypes: CompensationReviewStatusType[];
  initiatorDefault: boolean;
  initiatorUser: Employee;
  reviewerFilterStatusType: FilterStatusType;
  subscriptions: Subscription[] = [];
  linkReviewId: number = 0;
  hideReviewerFilter: boolean = false;

  filterPracticeValue: string;
  filterServiceValue: string;
  filterPrimaryCompetencyValue: string;
  filterReviewerValue: string;
  filterInitiatorValue: string;
  filterConsultantLevelValue: string;
  filterStatusValue: string;
  filterFiscalYearValue: string;
  filtersValue: string;

  isLoading: boolean = true;

  get numWorkflowsDisplay(): string {
    return this.filteredWorkflows
      ? `(${this.filteredWorkflows.filter((w) => w.compensationReviewId != 0).length})`
      : '';
  }

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private commonService: CommonService,
    private compensationService: CompensationService,
    private filterService: CompensationFilterService,
    private snackBarService: SnackBarService,
    private onlinerService: OnlinerService,
  ) {}

  ngOnInit() {
    const isRegularUser: boolean = this.authService.isRegularUser();
    this.commonService.getAllCommonData().subscribe(
      (commonData) => {
        if (!isRegularUser) {
          forkJoin([this.onlinerService.getReviewers(), this.onlinerService.getOnliners()]).subscribe(
            ([reviewers, employees]) => {
              this.checkRoles(employees, commonData.statusTypes);
              this.setupWorkflowList();
            },
            (error) => {
              this.snackBarService.error(error);
            },
          );
        } else {
          var regUserDefStatType = commonData.statusTypes.find(
            (status) => status.statusTypeId == StatusTypeEnum.Reviewed,
          );
          var regUserStatusTypeObj = {
            name: regUserDefStatType.statusTypeName,
            statusTypeId: regUserDefStatType.statusTypeId,
            sortOrder: regUserDefStatType.sortOrder,
          };
          this.filterCriteriaForm = this.filterService.getFilterCriteriaForm(
            true,
            true,
            defaultOnliner,
            defaultOnliner,
            regUserStatusTypeObj,
          );
          this.setupWorkflowList();
        }
      },
      (error) => {
        this.snackBarService.error(error);
      },
    );
  }

  private setupWorkflowList() {
    this.getCompensationWorkflows();
    this.subscriptions.push(
      this.compensationService.reviewUpdated$.subscribe((review) => {
        if (review) {
          this.getCompensationWorkflows();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private checkRoles(initiators: Employee[], statusTypes: StatusType[]) {
    this.isAdmin = this.authService.doesUserHaveRole([environment.roles.CompApprovAdmin]);
    this.isInitiator = this.authService.doesUserHaveRole([environment.roles.CompApprovInitiator]);
    this.isReviewer = this.authService.doesUserHaveRole([environment.roles.CompApprovReviewer]);
    this.isPayroll = this.authService.doesUserHaveRole([environment.roles.CompApprovPayroll]);
    this.isUser = this.authService.doesUserHaveRole([environment.roles.CompApprovUser]);

    this.loggedInUser = initiators.find((e) => e.userId == this.authService.getUserId().toLowerCase());
    this.userCanSeeFilter = this.isAdmin || this.isInitiator || this.isReviewer || this.isPayroll;
    this.userCanSeeStatusFilter = this.isAdmin || this.isInitiator || this.isReviewer;
    this.hideReviewerFilter = this.isReviewer && !this.isInitiator;

    this.initiatorDefault = this.isInitiator && !(this.isAdmin || this.isReviewer || this.isPayroll);
    if (this.initiatorDefault && initiators) {
      this.initiatorUser = initiators.find((init) => init.userId == this.authService.getUserId().toLowerCase());
      this.initiatorUser
        ? (this.filterCriteriaForm = this.filterService.getFilterCriteriaForm(
            true,
            true,
            defaultOnliner,
            this.initiatorUser,
            defaultFilterStatusType,
          ))
        : '';
    } else {
      this.filterCriteriaForm = this.filterService.getFilterCriteriaForm(
        true,
        true,
        defaultOnliner,
        defaultOnliner,
        defaultFilterStatusType,
      );
    }
  }

  public getCompensationWorkflows(ignoreCache: boolean = false) {
    forkJoin([
      this.commonService.getAllCommonData(),
      this.compensationService.getCompensationWorkflows(false),
    ]).subscribe(
      ([commonData, workflows]) => {
        this.closedStatusTypes = commonData.closedStatusTypes;
        this.workflows = workflows;
        this.filterWorkflows();

        this.route.queryParamMap.subscribe((q) => {
          this.linkReviewId = +q.get('reviewId');
        });
      },
      (error) => {
        this.snackBarService.error(error);
      },
    );
  }

  private filterWorkflows() {
    const filterCriteriaModel = this.filterService.getFilterCriteriaModel(false, false);
    if (this.workflows) {
      this.workflows.sort((a, b) => 0 - (a.onlinerEmployee.firstName < b.onlinerEmployee.firstName ? 1 : -1));
      this.workflows.sort((a, b) => 0 - (a.practice < b.practice ? 1 : -1));
      this.filteredWorkflows = this.workflows.filter(
        (f) =>
          this.filterService.filterPractice(f, filterCriteriaModel) &&
          this.filterService.filterConsultantLevel(f, filterCriteriaModel) &&
          this.filterService.filterPrimaryCompetency(f, filterCriteriaModel) &&
          this.filterService.filterReviewer(f, filterCriteriaModel) &&
          this.filterService.filterInitiator(f, filterCriteriaModel) &&
          this.filterService.filterServiceLine(f, filterCriteriaModel) &&
          this.filterService.filterstatusType(f, filterCriteriaModel),
      );
    }
    this.getFilterFormValues();
  }

  getFilterFormValues() {
    this.filterPracticeValue = this.filterCriteriaForm.form.controls.practice.value['name'];
    this.filterServiceValue = this.filterCriteriaForm.form.controls.serviceLine.value['name'];
    this.filterPrimaryCompetencyValue = this.filterCriteriaForm.form.controls.competency.value['name'];
    this.filterReviewerValue = this.filterCriteriaForm.form.controls.reviewer.value['fullName'];
    this.filterInitiatorValue = this.filterCriteriaForm.form.controls.initiator.value['fullName'];
    this.filterConsultantLevelValue = this.filterCriteriaForm.form.controls.consultantLevel.value['name'];
    this.filterStatusValue = this.filterCriteriaForm.form.controls.statusType.value['name'];

    const filters = [
      this.filterPracticeValue,
      this.filterServiceValue,
      this.filterPrimaryCompetencyValue,
      this.filterReviewerValue,
      this.filterInitiatorValue,
      this.filterConsultantLevelValue,
      this.filterStatusValue,
    ];
    if (this.isReviewer) {
      filters.splice(3, 1);
    }
    this.filtersValue = filters.join(', ');
  }
}
