import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import {
  ConsultantLevel,
  FilterableObject,
  Practice,
  PrimaryCompetency,
  ServiceLine,
  FilterStatusType,
} from '../core/models/draftSummary.model';
import { Employee } from './../core/models/employee.model';
import { AutoSuggestService } from './../core/services/auto-suggest.service';
import { CommonService } from './../core/services/common.service';
import { CompensationFilterService, FilterFormGroup } from './../core/services/compensation-filter.service';
import { OnlinerService, defaultOnliner } from './../core/services/onliner.service';
import { SnackBarService } from '../core/services/snackbar.service';
import {
  defaultPractice,
  defaultCompetency,
  defaultConsultantLevel,
  defaultServiceLine,
  defaultFilterStatusType,
} from '../core/services/compensation.service';
import { StatusTypeEnum } from '../core/models/enums.model';
import { AuthService } from '../core/services/auth.service';
import { environment } from 'src/environments/environment';
import { CommonData } from '../core/models/common.model';

const roles = environment.roles;

@Component({
  selector: 'app-compensation-filter-form',
  templateUrl: './compensation-filter-form.component.html',
  styleUrls: ['./compensation-filter-form.component.scss'],
})
export class CompensationFilterFormComponent implements OnInit, OnDestroy {
  @Input() searchCriteriaForm: FilterFormGroup;
  @Input() onWorkflowPage: boolean;
  @Input() userCanSeeStatusFilter: boolean = false;
  @Input() onProcessedFormsPage: boolean = false;
  @Input() reviewerDefault: boolean = false;
  @Input() initiatorDefault: boolean = false;
  @Input() reviewerUser: Employee = null;
  @Input() initiatorUser: Employee = null;
  @Input() reviewerStatusType: FilterStatusType = null;
  @Input() hideReviewerFilter: boolean = false;
  @Output() filterTriggered = new EventEmitter<boolean>();

  practices: Practice[];
  primaryCompetencies: PrimaryCompetency[];
  consultantLevels: ConsultantLevel[];
  reviewers: Employee[];
  initiators: Employee[];
  serviceLines: ServiceLine[];
  statusTypes: FilterStatusType[];
  fiscalYears: number[];

  filteredPractices: Observable<Practice[]>;
  filteredPrimaryCompetencies: Observable<PrimaryCompetency[]>;
  filteredConsultantLevels: Observable<ConsultantLevel[]>;
  filteredReviewers: Observable<Employee[]>;
  filteredInitiators: Observable<Employee[]>;
  filteredServiceLines: Observable<ServiceLine[]>;
  filteredStatusTypes: Observable<FilterStatusType[]>;
  filteredFiscalYears: Observable<number[]>;

  minDate: Date = new Date(new Date().getFullYear() - 5, 1, 1);
  isLoading: boolean;
  isSearching: boolean;
  isRegularUser: boolean;

  hideAsterisk: boolean = true;

  constructor(
    private autoSuggestService: AutoSuggestService,
    private commonService: CommonService,
    private compensationFilterService: CompensationFilterService,
    private onlinerService: OnlinerService,
    private snackBarService: SnackBarService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.isRegularUser = !this.authService.doesUserHaveRole([
      roles.CompApprovInitiator,
      roles.CompApprovAdmin,
      roles.CompApprovReviewer,
      roles.CompApprovPayroll,
    ]);
    this.getDropdownsData();
  }

  ngOnDestroy() {
    this.resetForm();
  }

  private getDropdownsData() {
    this.isLoading = true;

    if (this.isRegularUser) {
      this.isLoading = false;
    } else {
      forkJoin(
        this.onlinerService.getAssignedReviewers(),
        this.onlinerService.getPeopleCareOnlinersExcludingDefault(),
        this.commonService.getAllCommonData(),
      ).subscribe(
        ([reviewers, initiators, commonData]: [Employee[], Employee[], CommonData]) => {
          if (this.searchCriteriaForm) {
            this.practices = [...commonData.practices];
            this.practices.unshift(defaultPractice);
            this.filteredPractices = this.autoSuggestService.setControlFilter(
              this.searchCriteriaForm.practiceControl,
              this.practices,
            );

            this.primaryCompetencies = [...commonData.competencies];
            this.primaryCompetencies.unshift(defaultCompetency);
            this.filteredPrimaryCompetencies = this.autoSuggestService.setControlFilter(
              this.searchCriteriaForm.primaryCompetencyControl,
              this.primaryCompetencies,
            );

            this.consultantLevels = [...commonData.consultantLevels];
            this.consultantLevels.unshift(defaultConsultantLevel);
            this.filteredConsultantLevels = this.autoSuggestService.setControlFilter(
              this.searchCriteriaForm.consultantLevelControl,
              this.consultantLevels,
            );

            this.reviewers = [...reviewers];
            this.reviewers.unshift(defaultOnliner);
            this.filteredReviewers = this.autoSuggestService.setOnlinerFilter(
              this.searchCriteriaForm.reviewerControl,
              this.reviewers,
            );

            this.initiators = [...initiators];
            this.initiators.unshift(defaultOnliner);
            this.filteredInitiators = this.autoSuggestService.setOnlinerFilter(
              this.searchCriteriaForm.InitiatorControl,
              this.initiators,
            );

            this.serviceLines = [...commonData.serviceLines];
            this.serviceLines.unshift(defaultServiceLine);
            this.filteredServiceLines = this.autoSuggestService.setServiceLineFilter(
              this.searchCriteriaForm.serviceControl,
              this.serviceLines,
            );

            this.fiscalYears = [...commonData.fiscalYears];
            this.filteredFiscalYears = this.autoSuggestService.setFiscalYearFilter(
              this.searchCriteriaForm.fiscalYearControl,
              this.fiscalYears,
            );

            this.statusTypes = commonData.statusTypes.map((s) => {
              return { statusTypeId: s.statusTypeId, name: s.statusTypeName, sortOrder: s.sortOrder };
            });
            const newIX = this.statusTypes.findIndex((s) => s.statusTypeId == StatusTypeEnum.New);
            this.statusTypes.splice(newIX, 1);
            const processedIX = this.statusTypes.findIndex((s) => s.statusTypeId == StatusTypeEnum.Processed);
            this.statusTypes.splice(processedIX, 1);
            const rejectedIX = this.statusTypes.findIndex((s) => s.statusTypeId == StatusTypeEnum.Rejected);
            this.statusTypes.splice(rejectedIX, 1);
            const approvedIX = this.statusTypes.findIndex((s) => s.statusTypeId == StatusTypeEnum.Approved);
            this.statusTypes.splice(approvedIX, 1);
            const sysGeneratedIX = this.statusTypes.findIndex((s) => s.statusTypeId == StatusTypeEnum.SystemGenerated);
            this.statusTypes.splice(sysGeneratedIX, 1);

            this.statusTypes.unshift(defaultFilterStatusType);
            this.filteredStatusTypes = this.autoSuggestService.setControlFilter(
              this.searchCriteriaForm.statusTypeControl,
              this.statusTypes,
            );

            this.isLoading = false;
          } else {
            this.searchCriteriaForm = this.compensationFilterService.getFilterCriteriaForm(
              true,
              true,
              defaultOnliner,
              defaultOnliner,
              defaultFilterStatusType,
            );
            this.getDropdownsData();
          }
        },
        (error) => {
          this.isLoading = false;
          this.snackBarService.error(error);
        },
      );
    }
  }

  resetForm() {
    this.compensationFilterService &&
      this.compensationFilterService.resetForm(
        this.reviewerDefault,
        this.initiatorDefault,
        this.reviewerUser,
        this.initiatorUser,
        this.reviewerStatusType,
        this.fiscalYears && this.fiscalYears[0],
      );
    this.filterCompensations();
  }

  filterableDisplay = (option?: FilterableObject): string | undefined =>
    this.autoSuggestService.filterableDisplay(option);

  fiscalYearDisplay = (option?: number): number | undefined => this.autoSuggestService.fiscalYearDisplay(option);

  onlinerDisplay = (option?: Employee): string | undefined => this.autoSuggestService.onlinerDisplay(option);

  serviceLineDisplay = (option?: ServiceLine): string | undefined => this.autoSuggestService.serviceLineDisplay(option);

  filterCompensations() {
    if (this.searchCriteriaForm && this.searchCriteriaForm.form.valid) {
      this.filterTriggered.emit(true);
    }
  }
}
