<form *ngIf="!isLoading" [formGroup]="searchCriteriaForm.form" novalidate [style.fontSize.px]="18">
  <mat-form-field class="mr-3 col-md-3 search" [hideRequiredMarker]="hideAsterisk">
    <mat-label>Practice</mat-label>
    <input
      matInput
      name="practice"
      id="practice_name"
      placeholder="Choose Practice"
      [matAutocomplete]="practiceauto"
      formControlName="practice"
      required
    />
    <mat-error *ngIf="searchCriteriaForm.practiceError"> Please enter the name of the Practice.</mat-error>
    <mat-autocomplete
      #practiceauto="matAutocomplete"
      [displayWith]="filterableDisplay"
      autoActiveFirstOption
      (optionSelected)="filterCompensations($event)"
      panelWidth="280px"
    >
      <mat-option class="options" *ngFor="let option of filteredPractices | async" [value]="option">
        {{ filterableDisplay(option) }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="mr-3 col-md-3 search" [hideRequiredMarker]="hideAsterisk">
    <mat-label>Service</mat-label>
    <input
      matInput
      name="serviceLine"
      placeholder="Choose Service"
      [matAutocomplete]="serviceauto"
      formControlName="serviceLine"
      required
    />
    <mat-error *ngIf="searchCriteriaForm.serviceLineError"> Please enter the name of the Service.</mat-error>
    <mat-autocomplete
      #serviceauto="matAutocomplete"
      [displayWith]="filterableDisplay"
      autoActiveFirstOption
      (optionSelected)="filterCompensations($event)"
      panelWidth="280px"
    >
      <mat-option class="options" *ngFor="let option of filteredServiceLines | async" [value]="option">
        {{ serviceLineDisplay(option) }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="mr-3 col-md-3 search" [hideRequiredMarker]="hideAsterisk">
    <mat-label>Primary Competency</mat-label>
    <input
      matInput
      name="competency"
      placeholder="Choose Primary Competency"
      [matAutocomplete]="competencyauto"
      formControlName="competency"
      required
    />
    <mat-error *ngIf="searchCriteriaForm.primaryCompetencyError">
      Please enter the name of the Primary Competency.</mat-error
    >
    <mat-autocomplete
      #competencyauto="matAutocomplete"
      [displayWith]="filterableDisplay"
      autoActiveFirstOption
      (optionSelected)="filterCompensations($event)"
      panelWidth="350px"
    >
      <mat-option class="options" *ngFor="let option of filteredPrimaryCompetencies | async" [value]="option">
        {{ filterableDisplay(option) }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div>
    <mat-form-field *ngIf="!hideReviewerFilter" class="mr-3 col-md-3 search" [hideRequiredMarker]="hideAsterisk">
      <mat-label>Reviewer</mat-label>
      <input
        matInput
        name="reviewer"
        placeholder="Choose Reviewer"
        [matAutocomplete]="reviewersauto"
        formControlName="reviewer"
        required
      />
      <mat-error *ngIf="searchCriteriaForm.reviewersError"> Please enter the name of the Reviewer.</mat-error>
      <mat-autocomplete
        #reviewersauto="matAutocomplete"
        [displayWith]="onlinerDisplay"
        autoActiveFirstOption
        (optionSelected)="filterCompensations($event)"
        panelWidth="280px"
      >
        <mat-option class="options" *ngFor="let option of filteredReviewers | async" [value]="option">
          {{ onlinerDisplay(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="mr-3 col-md-3 search" *ngIf="onWorkflowPage" [hideRequiredMarker]="hideAsterisk">
      <mat-label>Initiator</mat-label>
      <input
        matInput
        name="initiator"
        placeholder="Choose Initiator"
        [matAutocomplete]="initiatorsauto"
        formControlName="initiator"
        required
      />
      <mat-error *ngIf="searchCriteriaForm.InitiatorsError"> Please enter the name of the Initiator.</mat-error>
      <mat-autocomplete
        #initiatorsauto="matAutocomplete"
        [displayWith]="onlinerDisplay"
        autoActiveFirstOption
        (optionSelected)="filterCompensations($event)"
        panelWidth="280px"
      >
        <mat-option class="options" *ngFor="let option of filteredInitiators | async" [value]="option">
          {{ onlinerDisplay(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="mr-3 col-md-3 search" [hideRequiredMarker]="hideAsterisk">
      <mat-label>Consultant Level</mat-label>
      <input
        matInput
        name="consultantLevel"
        placeholder="Choose Consultant Level"
        [matAutocomplete]="consultantlevelauto"
        formControlName="consultantLevel"
        required
      />
      <mat-error *ngIf="searchCriteriaForm.consultantLevelError">
        Please enter the name of the Consultant Level.</mat-error
      >
      <mat-autocomplete
        #consultantlevelauto="matAutocomplete"
        [displayWith]="filterableDisplay"
        autoActiveFirstOption
        (optionSelected)="filterCompensations($event)"
        panelWidth="280px"
      >
        <mat-option class="options" *ngFor="let option of filteredConsultantLevels | async" [value]="option">
          {{ filterableDisplay(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field
      class="mr-3 col-md-3 search"
      *ngIf="onWorkflowPage && userCanSeeStatusFilter"
      [hideRequiredMarker]="hideAsterisk"
    >
      <mat-label>Status</mat-label>
      <input
        matInput
        name="statusType"
        placeholder="Choose Status Type"
        [matAutocomplete]="statusauto"
        formControlName="statusType"
        required
      />
      <mat-error *ngIf="searchCriteriaForm.statusTypeError"> Please enter the name of the Status. </mat-error>
      <mat-autocomplete
        #statusauto="matAutocomplete"
        [displayWith]="filterableDisplay"
        autoActiveFirstOption
        (optionSelected)="filterCompensations($event)"
        panelWidth="280px"
      >
        <mat-option class="options" *ngFor="let option of filteredStatusTypes | async" [value]="option">
          {{ filterableDisplay(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="mr-3 col-md-3 search" *ngIf="!onWorkflowPage" [hideRequiredMarker]="hideAsterisk">
      <mat-label>Fiscal Year</mat-label>
      <input
        matInput
        name="fiscalYear"
        placeholder="Choose Fiscal Year"
        [matAutocomplete]="yearauto"
        formControlName="fiscalYear"
        required
      />
      <mat-error *ngIf="searchCriteriaForm.fiscalYearError"> Please enter the Fiscal Year. </mat-error>
      <mat-autocomplete
        #yearauto="matAutocomplete"
        [displayWith]="fiscalYearDisplay"
        autoActiveFirstOption
        (optionSelected)="filterCompensations($event)"
        panelWidth="280px"
      >
        <mat-option class="options" *ngFor="let option of filteredFiscalYears | async" [value]="option">
          {{ fiscalYearDisplay(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="row form-group">
    <div class="col-md-3 padding-left-15">
      <button
        mat-stroked-button
        type="button"
        class="btn reject-btn"
        (click)="resetForm()"
        [disabled]="!searchCriteriaForm.form.dirty"
      >
        RESET
      </button>
    </div>
  </div>
</form>

<div *ngIf="isLoading" class="loading m-auto"></div>
